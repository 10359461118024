import {
  convertVanityId,
  getHeroesRanking,
  getMatchesHighlights,
  getTopUsers,
  HeroRank,
  TopPlayer,
} from "@portal-frontend-ssr/blast-api";
import { getStaticDeadlockHeroes, Hero } from "@portal-frontend-ssr/blast-data-api";
import { cdn15Min, getHeadersFromLoader } from "@portal-frontend-ssr/ssr-utils";
import { Footer, Icon, PageSlot } from "@portal-frontend-ssr/ui";
import { ActionFunctionArgs, redirect } from "@remix-run/node";
import { Form, json, Link, useActionData, useLoaderData } from "@remix-run/react";
import { useEffect, useMemo, useState } from "react";
import SteamID from "steamid";
import bg from "~/shared/assets/deadlock.jpg";
import { defaultTo } from "~/shared/helpers/defaultTo";
import { AllHeroesTable } from "../../shared/components/AllHeroesTable";
import { MatchStatCards } from "../matches._index/components/MatchStatCards";
import { HighestWinHeroes } from "./components/PromotedHeroes/HighestWinHeroes";
import { TrendingHeroes } from "./components/PromotedHeroes/TrendingHeroes";
import { lookupSteamProfiles } from "./helpers/lookupSteamProfiles";
import classNames from "classnames";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { AdContainer } from "~/shared/components/AdContainer";
import { StickySideRailAds } from "~/shared/components/StickySideRailAds";

export const loader = async () => {
  const [matchesHighlights, heroesRanking, heroes, users] = await Promise.all([
    getMatchesHighlights(),
    getHeroesRanking().catch(defaultTo<HeroRank[]>([])),
    getStaticDeadlockHeroes().catch(defaultTo<Hero[]>([])),
    getTopUsers(10).catch(defaultTo<{ byWins: TopPlayer[]; byMatches: TopPlayer[] }>({ byWins: [], byMatches: [] })),
  ]);

  const accountIdToSteamProfileMap = await lookupSteamProfiles({ topPlayers: [...users.byMatches, ...users.byWins] });

  const mappedHeroes = heroes.reduce(
    (acc, hero) => {
      acc[hero.heroId] = hero;
      return acc;
    },
    {} as Record<string, Hero>,
  );

  return json(
    {
      matchesHighlights,
      heroesRanking,
      mappedHeroes: mappedHeroes,
      heroes,
      users,
      steamProfiles: accountIdToSteamProfileMap,
    },
    {
      headers: cdn15Min,
    },
  );
};

export const headers = getHeadersFromLoader;

export async function action({ request }: ActionFunctionArgs) {
  const body = await request.formData();
  const userSource = body.get("userSource");
  if (!userSource) return;

  let toTest = userSource
    .toString()
    .replaceAll("https://steamcommunity.com/id/", "")
    .replaceAll("http://steamcommunity.com/id/", "")
    .replaceAll("https://steamcommunity.com/profiles/", "")
    .replaceAll("http://steamcommunity.com/profiles/", "");

  // If last character is a slash, remove it
  if (toTest[toTest.length - 1] === "/") {
    toTest = toTest.slice(0, -1);
  }
  try {
    const steamIdInstance = new SteamID(toTest);
    return redirect(`/users/${steamIdInstance.accountid}`);
  } catch {
    return convertVanityId(toTest)
      .then((data) => {
        return redirect(`/users/${data.accountId}`);
      })
      .catch(() => {
        return json({ errorMessage: "User not found. Please make sure the Steam ID or URL is correct." });
      });
  }
}
export default function Index() {
  const { matchesHighlights, heroesRanking, heroes, mappedHeroes } = useLoaderData<typeof loader>();
  const data = useActionData<typeof action>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setIsSubmitting(false);
    }
  }, [data, isSubmitting]);

  const heroIds = useMemo(() => {
    return new Set<string>(heroes.map((hero) => hero.heroId));
  }, [heroes]);

  const filteredHeroRankings = useMemo(() => {
    return heroesRanking.filter((heroRanking) => heroIds.has(heroRanking.heroId));
  }, [heroesRanking, heroIds]);

  const trendingHeroes = useMemo(
    () =>
      filteredHeroRankings
        .sort((a, b) => {
          return (b.trendPercentage ?? 0) - (a.trendPercentage ?? 0);
        })
        .slice(0, 5),
    [filteredHeroRankings],
  );

  const highestWinHeroes = useMemo(
    () =>
      filteredHeroRankings
        .sort((a, b) => {
          return b.winRate - a.winRate;
        })
        .slice(0, 5),
    [filteredHeroRankings],
  );

  return (
    <>
      <StickySideRailAds className="top-20" />
      <AdContainer className="hidden md:block" type="horizontal_sticky" />
      <AdContainer className="block md:hidden" type="mobile_horizontal_sticky" />
      <div
        className="min-h-fill-available m-nav custom-scrollbar w-full pb-8"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundAttachment: "fixed",
        }}
      >
        <PageSlot>
          <div className="flex w-full flex-col gap-5 overflow-auto pt-5 md:flex-row">
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-5">
                <div className="relative flex justify-start gap-2">
                  <Form
                    method="post"
                    onSubmit={() => {
                      setIsSubmitting(true);
                    }}
                    className="w-full"
                  >
                    <input
                      type="text"
                      placeholder="Enter a SteamID or a Steam Profile Link"
                      className="w-full rounded-full border border-primary-100 bg-background-100 py-2 pl-5 pr-16 font-style-body-b2 placeholder:text-white focus:border-primary-100 focus:placeholder:text-neutral disabled:border-neutral"
                      required
                      name="userSource"
                    />
                    <button
                      type="submit"
                      className="absolute right-0 top-1/2 z-10 flex h-full w-12 -translate-y-1/2 items-center justify-center pr-4"
                      aria-label="search"
                    >
                      {data ? (
                        <div>
                          <ArrowPathIcon className="size-5 animate-spin text-primary-100" />
                        </div>
                      ) : (
                        <Icon
                          icon="magnifyingGlass"
                          className="absolute right-0 top-1/2 z-10 flex h-full w-10 -translate-y-1/2 items-center justify-center pr-6"
                        />
                      )}
                    </button>
                  </Form>
                </div>
                <div className="flex flex-col justify-start gap-2">
                  <h1 className="hidden shrink-0 pb-2 capitalize font-style-heading-h1 md:block">Deadlock heroes</h1>
                </div>
                <div className="flex flex-col gap-3 md:flex-row">
                  <HighestWinHeroes heroes={highestWinHeroes} mappedHeroes={mappedHeroes} />
                  <TrendingHeroes heroes={trendingHeroes} mappedHeroes={mappedHeroes} />
                </div>
              </div>
              {matchesHighlights !== null ? (
                <div className="flex w-full flex-col gap-4 pt-12">
                  <div className="mb-4 flex w-full items-center justify-between">
                    <h1 className="capitalize font-style-heading-h2">Matches</h1>

                    <div className="relative w-full items-center md:w-96">
                      <p
                        className={classNames(
                          "absolute inset-x-auto -bottom-5 mx-auto w-full text-center text-error font-style-body-b3",
                        )}
                      >
                        {data?.errorMessage}
                      </p>
                    </div>
                  </div>
                  <MatchStatCards {...matchesHighlights} />
                  <div className="flex w-full justify-center">
                    <Link to="/matches" className="button-tertiary">
                      See most recent matches
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="md:min-w-86">
              <AllHeroesTable reduced={true} heroesRanks={filteredHeroRankings} heroes={heroes} />
            </div>
          </div>
        </PageSlot>
      </div>
      <Footer />
    </>
  );
}
