import { HeroRank } from "@portal-frontend-ssr/blast-api/src/gamedataDeadlock/getHeroStats/schemas/HeroTrendSchena";
import classNames from "classnames";
import { TextColumn } from "./components/TextColumn";
import { Hero } from "@portal-frontend-ssr/blast-data-api";
import { Link } from "@remix-run/react";
import { generateDeadlockHeroImage } from "@portal-frontend-ssr/blast-assets-api";

interface PromotedHeroesProps {
  heroes: HeroRank[];
  mappedHeroes: Record<string, Hero>;
}

export function HighestWinHeroes({ heroes, mappedHeroes }: PromotedHeroesProps) {
  return (
    <div className="flex w-full flex-col gap-4">
      <span className="font-style-label-l3">Highest win% heroes</span>
      <table className="w-full px-2">
        <thead className="">
          <tr className="text-neutral font-style-label-l4">
            <th className="pb-1">Rank</th>
            <th className="pb-1 pl-5 text-start">Hero</th>
            <th className="pb-1">Win%</th>
            <th className="pb-1">Matches</th>
            <th className="pb-1">Pick %</th>
          </tr>
        </thead>
        <tbody className="divide-y-2 divide-background-100">
          {heroes.map((hero) => (
            <tr
              key={hero.heroId}
              className="relative h-9 bg-background-95 px-4 font-style-body-b1 hover:bg-background-90"
            >
              <td className="rounded-s-small text-center font-style-body-b3">#{hero.winRank}</td>
              <td className="relative">
                <div className="flex h-full items-center gap-3 pl-4">
                  <Link
                    to={`/heroes/${mappedHeroes[hero.heroId].heroSlug}`}
                    className="absolute left-0 top-0 size-full"
                  />
                  <img
                    className="size-6 rounded-full object-cover"
                    src={generateDeadlockHeroImage({ category: "card", heroId: hero.heroId })}
                    alt={hero.heroName}
                  />{" "}
                  <span className="font-style-body-b3">{hero.heroName}</span>
                </div>
              </td>
              <TextColumn
                slug={`/heroes/${mappedHeroes[hero.heroId].heroSlug}`}
                text={`${(hero.winRate * 100).toFixed(2)}%`}
                classes={classNames({
                  "text-success": hero.winRate * 100 > 50,
                  "text-error": hero.winRate * 100 < 50,
                })}
              />
              <TextColumn slug={`/heroes/${mappedHeroes[hero.heroId].heroSlug}`} text={`${hero.matchesPlayed}`} />
              <TextColumn
                slug={`/heroes/${mappedHeroes[hero.heroId].heroSlug}`}
                text={`${(hero.pickRate * 100).toFixed(2)}%`}
                isEnd
              />
            </tr>
          ))}
        </tbody>
      </table>
      <Link to="/heroes" className="mx-auto text-primary-100 font-style-label-l3">
        See all heroes
      </Link>
    </div>
  );
}
